.footer-wrapper {
  background-color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  text-align: center;
}
.footer-wrapper h1 {
  font-size: 1rem;
  font-weight: 400;
  color: #ffff;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0.5rem;
}
.social-media {
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
.social-media > * {
  margin: 1rem;
  font-size: 1.6rem;
}
a {
  color: #ffff;
}
.line {
  width: "80%";
  margin: "auto";
}
.emailIcon {
  display: none;
}
@media screen and (min-width: 900px) {
  .footer-wrapper {
    border: none;
    top: 45%;
    bottom: 90%;
    left: -2.4%;
    width: 10%;
  }
  .emailIcon {
    display: block;
  }
  .social-media {
    flex-direction: column;
    color: #fff;
  }

  a {
    color: #fff;
  }
  a:hover {
    transform: scale(1.5);
    transition: all 1s ease-in-out;
  }
  .footer-wrapper > a {
    display: none;
  }
  .line {
    display: none;
  }
}
