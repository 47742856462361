.content-wrapper {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  margin: auto;
}

.cinema_work > div {
  padding-top: 0.5rem;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.thumbnails {
  margin-top: 1rem;
}
.pc {
  display: none;
}
.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.cinema_content {
  color: #ffff;
}
.description {
  padding-bottom: 1rem;
  font-style: italic;
  font-size: 1rem;
}
.item {
  display: flex;
}

.item > p {
  font-size: 1rem;
  margin: 0;
}
.show-all-grid-item {
  display: none;
}
/*show all*/

@media screen and (min-width: 900px) {
  .mobile {
    display: none;
  }
  /*show */
  .show-all-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 2rem;
  }
  .show-all-grid-item {
    display: block;
    grid-column: span 3;
    position: relative;
  }
  .hover-overlay1 p {
    font-size: 1.2rem;
  }
  .hover-overlay1 {
    position: absolute;
    bottom: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    text-align: center;
  }
  .show-all-grid-item:hover .hover-overlay1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
  }
  .pc {
    display: block;
  }
  .slider-img {
    height: 50%;
  }
  .content-wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  .thumbnails {
    position: relative;
  }

  .desktop-modal {
    display: none;
  }

  .thumbnails img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }

  .load{
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }

  .play-icon {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .player-wrapper {
    width: 100%;
    overflow: hidden;
  }

  .title {
    margin-top: 6px;
    font-size: 1.3rem;
    font-weight: 600;
  }

  .time-wrapper {
    margin-top: 10px;
  }

  .details {
    margin-top: 6px;
    font-weight: 100;
    font-size: 12px;
    color: black;
  }

  .fallbackImage {
    width: 88.2vw;
    height: 30vh;
  }

  .overlay-wrapper {
    background-color: #d9d9d9;
    display: flex;
    background-color: #000;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    padding-top: 3.5rem;
    position: relative;
    z-index: 940;
  }

  .close-btn {
    margin-top: 1px;
    position: absolute;
    z-index: 999;
    top: 10px;
    right: 10px;
  }

  .thumbnail-desktop {
    display: none;
  }
}

@media screen and (width > 900px) {
  .slider-container {
    width: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .active {
    display: inline-block;
  }

  .inactive {
    display: none;
  }

  .slides {
    height: 80vh;
    width: 98vw;
    position: relative;
  }

  .slide-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .slide-title,
  .slide-text {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 50px;
    position: absolute;
    text-align: center;
    top: 40%;
    z-index: 10;
  }

  .slide-text {
    top: 65%;
    font-size: 2rem;
  }

  /* causes problem to player in landscape */

  /* .overlay {
    display: none;
  } */

  .thumbnails {
    display: none;
  }

  .desktop-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .desktop-modal-wrapper {
    background-color: #d9d9d9;
    background-color: #000;
    display: flex;
    width: 80%;
    height: 70%;
    padding: 1.5rem;
    position: relative;
  }

  .close-btn-desktop {
    margin-top: 1px;
    position: absolute;
    z-index: 999;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .cinema_content-desktop {
    color: white;
    margin-left: 30px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
  }
}
