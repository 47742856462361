@media screen and (min-width: 900px) {
  .pc {
    display: block;
  }
}
@media screen and (width > 900px) {
  .slider-container {
    width: 90%;
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  .homepageslider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
 
  .slider-text-wrapper{
    z-index:1;
  }
  .active {
    display: inline-block;
  }

  .inactive {
    display: none;
  }

  .slides {
    height: 80vh;
    width: 98vw;
    position: relative;
  }

  .slide-image img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .slider-text-wrapper {
    width: 90%;
    color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .slider-title {
    margin-top: 150px;
    margin-bottom: 20px;
    text-align: center;
  }

  .play-icon-wrapper {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  .icon-play {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .play-icon-text {
    font-size: 25px;
    font-weight: 500;
    margin-top: 4px;
  }

  .show-more-button {
    border: 1px solid white;
    width: 150px;
    height: 40px;
    display: flex;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    gap: 4px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .show-more-button h1 {
    font-weight: 500;
  }

  .plus,
  .close {
    margin-top: 3px;
    font-size: 20px;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    z-index:1;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }

  .next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
}
