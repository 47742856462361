.about {
  background: url("../../images/Collage.png") center no-repeat;
  background-size: cover;
  min-height: 130px;
  margin-top:2rem;
  object-fit: cover;
}
.pc-view{
  display: none;
}
.about > * {
  position: relative;
  z-index: 10;
}
.about-text {
  display: none;
}
.mobile-text {
  display: block;
  color: #ffff;
}
.awards {
  margin: 1rem 0rem;
}
.awards > p {
  margin: 0;
}
/*awards*/
.award-layout{
  width:90%;
  margin:auto;
  margin-top:1rem;
}
.award-box{
  margin-top:2rem;
}

strong{
  border-bottom:1px solid #fff;
}
.award-grid-item :first-child{
  display:flex;
  align-items: center;
}
.award-grid-item{
    grid-column:1;
    border-radius:30px;
    padding-top:1rem;
    margin-top:1rem;
}
.award-grid-item h3{
  font-size:1rem;
}
.award-layout h1{
  font-size:1.5rem;
}
.award-grid-item p{
  margin-top:0.2rem;
}
.download {
  width: 90%;
  margin: auto;
}
.download button{
  padding:0.5rem;
  border:1px solid #fff;
  background:#000;
  color: #fff;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.download button:hover{
  background:#fff;
  color: #000;
}
.award-layout h1{
  text-align:center;
}
@media screen and (min-width: 900px) {
  .award-grid-item p{
    margin-top:0.6rem;
  }
  .mobile-text {
    display: none;
  }
  .aboutpc-grid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:2rem;
    background-color: #fff;
    color: #000;
    padding:2rem;
  }
  /* .aboutpc-grid :first-child, .aboutpc-grid :last-child{
    margin-top:3rem;
  } */
  .pc-view{
    display: block;
    width:80%;
    margin:auto;
  }
  .lalitimg{
    background: url("../../images/lalit.jpg") center no-repeat;
    background-size: cover;
    height:500px;
    object-fit: cover;
   
  }
  .award-layout{
    width:80%;
  }
  .award-grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:2rem;
    margin-top:2rem;
  
  }
  .award-grid-item{
    grid-column: span 1;
  
    border-radius:30px;
    padding:1rem;
  }
  .award-grid-item h3{
    font-size:1.2rem;
  }
  .award-layout h1{
    font-size:2.5rem;
  }
  h3{
    font-size:1.5rem;
  }
  .download {
    width: 80%;
    margin: auto;
    margin-top:3rem;
  }
  .download button{
    padding:1rem;
    border:1px solid #fff;
    background:#000;
    font-size:1.5rem;
    color: #fff;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
  }
}