@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mouse+Memoirs&family=Open+Sans:ital@1&family=Poppins:wght@100;300&display=swap");

.name {
  text-align: center;
}
nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name h1 {
  font-size: 2rem;
  font-weight: bold;
  font-family: "Mouse Memoirs", sans-serif;
}

.name h2 {
  font-size: 19.1px;
  font-weight: 500;
}

a:-webkit-any-link {
  text-decoration: none;
}

.nav-list {
  list-style: none;
}

@media screen and (max-width: 900px) {
  .menu {
    padding-top: 10px;
    letter-spacing: 1px;
    color: #a0a09f;
    text-align: center;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 900;
    cursor: pointer;
  }
  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menuOpt {
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-height: 0;
    transition: max-height 1s ease-out;
    overflow: hidden;
    background: #d5d5d5;
  }

  .menuOpt.start {
    max-height: 500px;
    transition: max-height 1s ease-in;
  }

  .name h1 {
    letter-spacing: 1px;
    margin-left: 0.5px;
  }

  .name h2 {
    letter-spacing: 0.1px;
    margin-top: 5px;
    font-size: 15px;
    color: #a0a09f;
  }

  .nav-list {
    padding: 10px 0;
  }

  .nav-list li {
    padding: 10px;
    color: #a0a09f;
    letter-spacing: 2px;
  }
}

@media screen and (width > 900px) {
  .menu {
    display: none;
  }
  .name {
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .menuOpt {
    margin-top: 100px;
  }

  .nav-list {
    display: flex;
    align-items: center;
  }
  .nav-list > li {
    margin: 2rem;
  }
}
