@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
body {
  background-color: #000;
}
img {
  max-width: 100%;
  display: block;
}
p {
  margin-top: 1rem;
}
.container {
  width: 90%;
  margin: auto;
}
